import React from "react";
import Layout from "@layout";
import SEO from "@components/seo";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils/functions";
import HeroSection from "../container/hero-area/layout-01";
import AboutUsSection from "../container/about-us-area/layout-01";
import AdvantagesSection from "../container/advantages";
import TestimonialSection from "../container/testimonial/layout-01";
import FloorPlanTabArea from "../container/floor-plan/floor-nav";
import TabContentOne from "../container/floor-plan/floor-content/layout-01";
import FeatureSection from "../container/feature";
import PlaygroundSection from "../container/playground";
import MapLocationListSection from "../container/map-location-list-area";
import BlogSection from "../container/blog";
import ContactAgentSection from "../container/contact-agent";
import Footer from "../layouts/footer/layout-01";
import Header from "../layouts/header/layout-01";
import "../style/style.css";

const HomePage = ({ data }) => {
    const globalContent = normalizedData(data?.allGeneral?.nodes || []);
    const content = normalizedData(data?.page?.content || []);
    return (
        <Layout>
            <SEO title="Home page" pathname="/" />
            <Header
                data={{
                    ...globalContent?.["menu"],
                }}
            />
            <HeroSection data={content?.["hero-section"]} />
            <AboutUsSection data={content?.["about-us-section"]} />
            {/* <TestimonialSection data={content?.["testimonial-section"]} /> */}
            <AdvantagesSection data={content?.["advantages-section"]} />
            {/* <FloorPlanTabArea>
                <TabContentOne data={content?.["studio-floor"]} />
                <TabContentOne data={content?.["2-rooms-floor"]} />
                <TabContentOne data={content?.["3-rooms-floor"]} />
                <TabContentOne data={content?.["penthouse-floor"]} />
                <TabContentOne data={content?.["double-height-floor"]} />
            </FloorPlanTabArea> */}
            {/* <FeatureSection data={content?.["features-section"]} /> */}
            <PlaygroundSection data={content?.["playground-section"]} />
            {/* <MapLocationListSection
                data={content?.["map-location-list-section"]}
            />
            <BlogSection
                data={{
                    ...content?.["latest-section"],
                    items: data?.latestPosts?.nodes,
                }}
            /> */}
            {/* <ContactAgentSection
                data={{
                    ...content?.["contact-agent-section"],
                    agent: data?.allAgentTeam?.nodes,
                }}
            /> */}
            <Footer data={{ ...globalContent?.["footer"] }} />
        </Layout>
    );
};

HomePage.propTypes = {
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        latestPosts: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allAgentTeam: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};
export const query = graphql`
    query HomePageQuery {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    ...Footer
                }
            }
        }
        page(title: { eq: "home" }, pageType: { eq: homepage }) {
            content {
                ...PageContentAll
            }
        }
        allAgentTeam {
            nodes {
                id
                name
                slug
                phone
                email
                designation
                image {
                    alt
                    src {
                        childImageSharp {
                            gatsbyImageData(quality: 100)
                        }
                    }
                }
            }
        }
        latestPosts: allArticle(
            limit: 3
            sort: { fields: postedAt___date, order: DESC }
        ) {
            nodes {
                ...Articles
                image {
                    alt
                    src {
                        childImageSharp {
                            gatsbyImageData(
                                height: 293
                                quality: 100
                                width: 476
                            )
                        }
                    }
                }
            }
        }
    }
`;
export default HomePage;
